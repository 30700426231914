import styled from "styled-components";

const Footer = () => {
  return (
    <div>
      <Container />
    </div>
  );
};

export default Footer;

const Container = styled.div`
  height: 10em;
  background: #333333;
`;
